"use client";

import Link from "next/link";
import { cn } from "../lib/utils";

export default function Logo(props: { className?: string }) {
  const { className } = props;
  return (
    <Link href="/" className={cn("transition-opacity hover:opacity-75", className)}>
      <SvgLogo className="min-w-[100px] text-foreground" />
    </Link>
  );
}

export const SvgLogo = (props: { className?: string }) => {
  const { className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.00 0.00 1200.00 600.00"
      className={className}
      role="img"
      aria-label="crayobois"
    >
      <path
        fill="currentColor "
        d="
  M 60.05 224.51
  C 60.23 241.05 70.35 255.87 88.46 254.40
  C 99.62 253.49 106.13 246.97 111.60 237.69
  Q 111.92 237.14 112.47 237.45
  L 149.61 258.37
  A 0.56 0.56 0.0 0 1 149.82 259.14
  Q 131.02 292.00 94.08 295.60
  C 73.09 297.64 53.02 292.30 37.26 278.77
  Q 20.85 264.68 15.43 243.60
  C 5.85 206.39 24.41 168.62 61.35 156.07
  Q 80.85 149.44 102.68 153.54
  C 122.73 157.31 138.70 169.51 148.91 187.59
  A 0.44 0.43 -30.2 0 1 148.75 188.18
  L 111.45 209.97
  Q 110.91 210.28 110.63 209.73
  C 104.47 197.67 94.08 191.71 80.87 194.08
  C 66.83 196.60 59.90 210.90 60.05 224.51
  Z"
      />
      <path
        fill="currentColor "
        d="
  M 700.6992 220.9696
  A 75.51 72.26 -2.3 0 1 628.1499 296.2018
  A 75.51 72.26 -2.3 0 1 549.8008 227.0304
  A 75.51 72.26 -2.3 0 1 622.3501 151.7982
  A 75.51 72.26 -2.3 0 1 700.6992 220.9696
  Z
  M 626.3947 255.2190
  A 31.24 28.87 87.9 0 0 654.1006 222.9421
  A 31.24 28.87 87.9 0 0 624.1053 192.7810
  A 31.24 28.87 87.9 0 0 596.3994 225.0579
  A 31.24 28.87 87.9 0 0 626.3947 255.2190
  Z"
      />
      <path
        fill="currentColor "
        d="
  M 997.6992 220.9696
  A 75.51 72.26 -2.3 0 1 925.1499 296.2018
  A 75.51 72.26 -2.3 0 1 846.8008 227.0304
  A 75.51 72.26 -2.3 0 1 919.3501 151.7982
  A 75.51 72.26 -2.3 0 1 997.6992 220.9696
  Z
  M 923.3947 255.2190
  A 31.24 28.87 87.9 0 0 951.1006 222.9421
  A 31.24 28.87 87.9 0 0 921.1053 192.7810
  A 31.24 28.87 87.9 0 0 893.3994 225.0579
  A 31.24 28.87 87.9 0 0 923.3947 255.2190
  Z"
      />
      <path
        fill="currentColor "
        d="
  M 1115.57 189.60
  C 1110.90 191.27 1110.84 196.98 1115.15 199.19
  Q 1119.50 201.42 1124.73 202.47
  C 1150.11 207.53 1187.77 213.80 1188.96 246.68
  C 1190.16 279.96 1162.88 294.49 1133.55 295.85
  C 1106.77 297.09 1078.84 290.59 1057.98 273.20
  Q 1057.42 272.73 1057.89 272.17
  L 1081.90 243.42
  Q 1082.17 243.09 1082.51 243.35
  Q 1104.41 259.73 1131.53 259.45
  C 1134.63 259.41 1140.70 258.74 1141.73 254.96
  C 1143.32 249.12 1136.07 247.05 1131.89 246.03
  C 1107.87 240.21 1067.09 235.31 1065.12 202.32
  Q 1063.86 181.34 1077.52 167.74
  C 1093.08 152.23 1117.38 150.71 1138.09 152.72
  C 1155.38 154.40 1171.39 159.91 1185.30 170.64
  A 0.51 0.51 0.0 0 1 1185.40 171.35
  L 1163.52 201.80
  Q 1163.14 202.34 1162.60 201.95
  Q 1145.59 189.78 1123.92 188.53
  Q 1119.31 188.26 1115.57 189.60
  Z"
      />
      <path
        fill="currentColor "
        d="
  M 202.25 254.75
  L 202.25 292.50
  Q 202.25 293.00 201.75 293.00
  L 156.25 293.00
  A 0.25 0.25 0.0 0 1 156.00 292.75
  L 156.00 156.00
  A 0.98 0.98 0.0 0 1 156.98 155.02
  Q 192.40 154.93 227.05 155.06
  C 258.83 155.18 286.79 169.76 286.00 204.97
  Q 285.37 233.17 259.27 246.28
  A 0.43 0.43 0.0 0 0 259.11 246.91
  L 284.39 283.46
  A 0.33 0.33 0.0 0 0 284.96 283.40
  L 339.08 154.28
  Q 339.19 154.00 339.49 154.00
  L 384.51 154.00
  Q 385.06 154.00 385.27 154.51
  L 443.15 292.62
  A 0.25 0.25 0.0 0 1 442.92 292.96
  L 393.70 292.96
  Q 393.07 292.96 392.84 292.38
  L 385.88 275.04
  Q 385.77 274.75 385.46 274.75
  L 337.93 274.75
  A 0.64 0.64 0.0 0 0 337.33 275.16
  L 330.55 292.56
  A 0.65 0.64 -79.5 0 1 329.97 292.96
  L 238.73 293.01
  Q 238.20 293.01 237.90 292.57
  L 212.42 254.51
  Q 212.08 254.00 211.47 254.00
  L 203.00 254.00
  Q 202.25 254.00 202.25 254.75
  Z
  M 202.26 194.04
  L 202.22 220.62
  A 0.33 0.33 0.0 0 0 202.55 220.95
  L 224.45 220.99
  A 15.51 12.69 0.1 0 0 239.98 208.32
  L 239.98 206.46
  A 15.51 12.69 0.1 0 0 224.49 193.75
  L 202.59 193.71
  A 0.33 0.33 0.0 0 0 202.26 194.04
  Z
  M 349.33 241.23
  A 0.35 0.35 0.0 0 0 349.66 241.71
  L 373.80 241.71
  A 0.35 0.35 0.0 0 0 374.13 241.23
  L 362.06 209.20
  A 0.35 0.35 0.0 0 0 361.40 209.20
  L 349.33 241.23
  Z"
      />
      <path
        fill="currentColor "
        d="
  M 489.59 199.74
  L 513.10 155.16
  A 0.30 0.30 0.0 0 1 513.36 155.00
  L 563.92 155.00
  A 0.30 0.30 0.0 0 1 564.17 155.45
  L 512.29 243.50
  A 0.30 0.30 0.0 0 0 512.25 243.65
  L 512.25 292.70
  A 0.30 0.30 0.0 0 1 511.95 293.00
  L 466.31 293.00
  A 0.30 0.30 0.0 0 1 466.01 292.70
  L 465.99 244.23
  A 0.30 0.30 0.0 0 0 465.95 244.08
  L 414.07 155.45
  A 0.30 0.30 0.0 0 1 414.33 155.00
  L 465.48 155.00
  A 0.30 0.30 0.0 0 1 465.74 155.16
  L 489.06 199.74
  A 0.30 0.30 0.0 0 0 489.59 199.74
  Z"
      />
      <path
        fill="currentColor "
        d="
  M 813.35 220.97
  C 830.21 226.49 841.66 235.39 841.49 254.73
  C 841.36 269.20 833.81 280.36 820.51 286.72
  C 809.31 292.08 797.50 293.09 784.68 293.07
  Q 747.19 293.03 712.60 293.06
  Q 712.00 293.06 712.00 292.46
  L 712.00 155.79
  A 0.79 0.79 0.0 0 1 712.79 155.00
  Q 753.03 154.98 792.03 155.03
  C 809.09 155.05 829.73 160.78 835.41 178.84
  Q 838.48 188.63 835.75 198.54
  C 832.85 209.07 824.46 216.27 813.34 220.31
  Q 812.39 220.65 813.35 220.97
  Z
  M 757.02 190.50
  L 756.98 208.46
  A 0.50 0.50 0.0 0 0 757.48 208.96
  L 779.44 209.00
  A 12.04 8.62 0.1 0 0 791.50 200.40
  L 791.50 198.68
  A 12.04 8.62 0.1 0 0 779.48 190.04
  L 757.52 190.00
  A 0.50 0.50 0.0 0 0 757.02 190.50
  Z
  M 757.00 238.04
  L 757.00 257.44
  A 0.55 0.55 0.0 0 0 757.55 257.99
  L 781.78 257.99
  A 13.22 9.53 -0.0 0 0 795.00 248.46
  L 795.00 247.02
  A 13.22 9.53 0.0 0 0 781.78 237.49
  L 757.55 237.49
  A 0.55 0.55 0.0 0 0 757.00 238.04
  Z"
      />
      <rect
        fill="currentColor "
        x="1008.00"
        y="155.00"
        width="46.26"
        height="138.00"
        rx="0.60"
      />
      <path
        fill="currentColor "
        d="
  M 562.23 344.08
  Q 562.23 343.97 562.24 343.90
  Q 562.38 343.39 562.91 343.38
  Q 583.07 343.16 604.52 343.13
  Q 678.81 343.00 747.26 343.74
  Q 756.90 343.85 767.24 344.06
  Q 779.76 344.33 796.50 344.66
  Q 849.87 345.74 913.90 348.50
  Q 921.05 348.81 928.08 349.20
  Q 934.37 349.56 942.27 349.78
  C 943.50 349.82 944.56 350.27 945.88 350.27
  Q 954.01 350.25 961.17 350.93
  C 965.41 351.33 969.57 351.25 973.77 351.60
  Q 978.31 351.98 982.26 352.19
  C 992.96 352.75 1003.64 353.68 1014.45 354.36
  C 1018.84 354.63 1023.16 355.30 1027.60 355.42
  Q 1030.76 355.51 1033.88 356.00
  C 1036.60 356.43 1039.40 356.34 1042.21 356.60
  Q 1064.96 358.71 1089.99 361.52
  Q 1116.93 364.55 1145.24 369.26
  Q 1147.56 369.64 1149.42 370.30
  A 1.66 1.66 0.0 0 1 1150.53 372.00
  Q 1150.44 373.25 1150.27 374.56
  C 1150.06 376.18 1150.83 377.47 1150.26 379.09
  Q 1150.02 379.79 1150.20 380.50
  L 1150.57 381.91
  A 1.15 1.15 0.0 0 1 1149.65 383.33
  Q 1127.55 387.17 1104.50 390.11
  Q 1087.50 392.27 1069.44 394.15
  Q 1050.66 396.10 1049.54 396.19
  Q 984.83 401.65 914.25 404.83
  Q 872.44 406.71 837.50 407.54
  Q 834.23 407.62 830.64 407.72
  Q 702.86 411.33 563.01 409.97
  A 0.67 0.67 0.0 0 1 562.35 409.30
  Q 562.63 369.64 562.23 344.08
  Z"
      />
      <path
        fill="currentColor "
        d="
  M 123.62 410.08
  C 115.76 410.08 107.56 408.47 101.19 403.58
  C 98.59 401.58 95.69 397.35 95.69 393.80
  Q 95.74 377.22 95.69 359.83
  Q 95.68 357.07 97.04 354.50
  C 101.30 346.42 112.29 343.22 120.90 343.23
  Q 309.02 343.49 490.13 343.52
  Q 490.62 343.52 490.62 344.02
  L 490.64 409.24
  A 0.81 0.80 -90.0 0 1 489.84 410.05
  Q 337.66 409.96 185.33 410.06
  Q 176.92 410.07 165.67 409.84
  C 151.60 409.55 137.60 410.06 123.62 410.08
  Z
  M 474.95 352.37
  Q 474.77 351.36 473.74 351.34
  Q 453.53 350.95 432.39 350.93
  Q 283.39 350.73 130.52 350.64
  C 127.50 350.64 124.76 351.08 122.11 352.65
  A 1.04 1.04 0.0 0 0 121.85 354.22
  C 124.55 357.40 126.68 358.40 131.11 358.29
  C 145.41 357.92 159.71 358.14 173.75 358.15
  Q 309.41 358.25 442.25 357.83
  Q 457.14 357.78 473.56 357.76
  A 1.54 1.53 3.9 0 0 475.07 356.45
  Q 475.35 354.60 474.95 352.37
  Z"
      />
      <rect
        fill="currentColor "
        x="504.49"
        y="343.31"
        width="43.94"
        height="66.74"
        rx="0.26"
      />
      <path
        fill="currentColor "
        d="
  M 83.46 390.26
  A 1.22 1.22 0.0 0 1 82.24 391.47
  L 21.93 391.26
  A 11.07 7.12 0.2 0 1 10.88 384.10
  L 10.94 368.50
  A 11.07 7.12 0.2 0 1 22.03 361.42
  L 82.34 361.63
  A 1.22 1.22 0.0 0 1 83.56 362.86
  L 83.46 390.26
  Z"
      />
      <path
        fill="currentColor "
        d="
  M 1158.73 374.00
  A 0.87 0.87 0.0 0 1 1159.60 373.13
  L 1180.23 373.13
  A 6.38 3.82 0.0 0 1 1186.61 376.95
  L 1186.61 376.97
  A 6.38 3.82 0.0 0 1 1180.23 380.79
  L 1159.60 380.79
  A 0.87 0.87 0.0 0 1 1158.73 379.92
  L 1158.73 374.00
  Z"
      />
      <path
        fill="currentColor "
        d="
  M 173.36 417.65
  C 175.29 427.13 182.59 430.79 191.51 431.03
  Q 200.35 431.26 209.10 431.26
  Q 393.00 431.25 578.92 431.29
  Q 580.28 431.29 581.57 431.10
  A 0.97 0.97 0.0 0 1 582.68 431.99
  C 582.91 434.98 582.78 438.64 579.82 440.26
  C 577.61 441.48 574.47 442.01 571.98 442.06
  C 553.30 442.48 534.62 442.46 516.00 442.68
  Q 414.04 443.86 312.99 444.83
  Q 303.53 444.92 293.01 445.10
  C 270.30 445.49 246.78 445.40 223.49 445.79
  Q 194.26 446.29 166.08 446.59
  Q 156.20 446.69 150.48 445.56
  C 140.49 443.58 129.82 438.90 125.06 429.84
  C 122.94 425.80 122.89 421.69 123.09 417.38
  A 0.49 0.49 0.0 0 1 123.58 416.91
  L 172.66 417.07
  A 0.71 0.71 0.0 0 1 173.36 417.65
  Z"
      />
    </svg>
  );
};
